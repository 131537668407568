<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Create new Report",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      time_zone_local : moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'edit' ? 'edit' : this.mode;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    this.title = this.mode === 'edit' ? `Report: ${this.models?.code}`  : this.title;

    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_MANAGERMENT);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.redirectEdit(1);
    },
    UpdateActive(key){
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if(key == 'lock'){
        this.active_check = false
      }else{
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': {'_eq': this.$route.params.id}
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success',res);
        if(key == 'lock'){
          var query_t =  `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      let objects = {
        status: params?.status ?? null,
        category_id: params?.category_id ?? null,
        visit_id: params?.visit_id ?? 1,
        active : true,
        content : params?.description ?? '',
        property_id: params?.property_id ?? null,
        updated_at: moment.tz(new Date(), this.time_zone_local),
        updated_by: this.$store.getters.user
      }
      var list_file = params?.list_file ?? [];
      var list_video = params?.list_video ?? [];
      console.log('list_video____________',list_video)
      var list_id_file_delete = params?.list_id_file_delete ?? [];
      var list_id_file_video_delete = params?.list_id_file_video_delete ?? [];
      this.triggerSave = false;
      if(this.mode == 'add'){
        const id_report = await ApiRepository.post('/_api/property/get_code_report_admin');
        console.log("id_report", id_report.data.data.code);
        objects.code = id_report.data.data.code;
        objects.created_at = moment.tz(new Date(), this.time_zone_local);
        objects.created_by = this.$store.getters.user;
        await this.$apollo.mutate({
            mutation: DataServices.insertData('visit_report'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              console.log("list_filelist_filelist_filelist_filelist_file:",list_file)
              console.log()
              // if (params.process_video) {
              //   that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.VIDEO_REPORT, returning.id, params.process_video).then(() => {
              //   });
              // }
              if (list_video.length > 0) {
                console.log('Số lượng video là: ' + list_video.length);

                for (let i = 0; i < list_video.length; i++) {
                  let video = list_video[i]; // Lấy từng video
                  await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.VIDEO_REPORT, returning.id, video).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                      severity: 'error',
                      summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                      detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                      life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                  });
                }
              }
              if (list_file.length > 0) {
                console.log('Số lượng file là: ' + list_file.length);

                await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.REPORT_MANAGER, list_file).then(async (resImage) => {
                  console.log("resresres=====:", resImage);

                  if (resImage && resImage.length > 0) {
                    // Lặp qua từng hình ảnh đã tải lên
                    for (let i = 0; i < resImage.length; i++) {
                      var objectImage = resImage[i]; // Lấy từng tấm hình
                      await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.IMG_REPORT, returning.id, objectImage).catch(async error => {
                        console.log('error', error);
                        this.$toast.add({
                          severity: 'error',
                          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                          life: 3000
                        });
                        this.$commonFuction.is_loading(false);
                      });
                    }

                    // Sau khi tất cả các hình ảnh đã được cập nhật, thực hiện chuyển hướng
                    this.redirectEdit();
                  }
                });
              } else {
                this.redirectEdit();
              }

              this.redirectEdit();
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });


      }else{
        console.log('id_reportid_reportid_reportid_report',this.models?.code)

        console.log('ádfsadfdfasffadf',objects)
        // var user_id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('visit_report'),
            variables: variables
        }).then(async (res) => {
            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];



              // if (!that.$commonFuction.isEmpty(params.process_video)){
              //   //Nếu ko tồn tại video cũ thì update lại
              //   if (that.$commonFuction.isEmpty(params.process_video.old)){
              //     //Xóa video cũ
              //     that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.VIDEO_REPORT,returning.id).then(() => {
              //       that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.VIDEO_REPORT,returning.id,params.process_video).then(() => {
              //       });
              //     });
              //   }
              // }
              console.log('danhsachdeletevideo',list_id_file_video_delete)
              if (list_id_file_video_delete.length > 0){
                await this.$CoreService.deleteDataImageMulti(this.$constants.TYPE_IMAGE.VIDEO_REPORT, list_id_file_video_delete)
              }
              if (list_video.length > 0) {
                console.log('Số lượng video là: ' + list_video.length);

                for (let i = 0; i < list_video.length; i++) {
                  let video = list_video[i]; // Lấy từng video
                  await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.VIDEO_REPORT, returning.id, video).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                      severity: 'error',
                      summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                      detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                      life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                  });
                }
              }
              console.log('list_id_file_delete',list_id_file_delete)

              if (list_id_file_delete.length > 0){
                await this.$CoreService.deleteDataImageMulti(this.$constants.TYPE_IMAGE.IMG_REPORT, list_id_file_delete)
              }
              if (list_file.length > 0) {
                console.log('Số lượng file là: ' + list_file.length);

                await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.REPORT_MANAGER, list_file).then(async (resImage) => {
                  console.log("resresres=====:", resImage);

                  if (resImage && resImage.length > 0) {
                    // Lặp qua từng hình ảnh đã tải lên
                    for (let i = 0; i < resImage.length; i++) {
                      var objectImage = resImage[i]; // Lấy từng tấm hình
                      await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.IMG_REPORT, returning.id, objectImage).catch(async error => {
                        console.log('error', error);
                        this.$toast.add({
                          severity: 'error',
                          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                          life: 3000
                        });
                        this.$commonFuction.is_loading(false);
                      });
                    }
                  }

                });
              }


            }
          this.redirectEdit();

        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }

      console.log('statusstatusstatusstatusstatus',this.models.status, 'params',params.status)


      if (params.status !== this.models.status) {
        console.log('callaaaaapi')
        const id_user = params.uid;
        console.log('user id la ',id_user)
        var list_report_action = await ApiRepository.post('_api/common/send_push_admin',{
          "action_key": "REPORT_DETAIL",
          "object_id":  this.models.id,
          "uid": id_user,
        });
        console.log("list_report_action", list_report_action);
      }

      this.$commonFuction.is_loading(false);
    },
    async redirectEdit(is_not_noti=0) {
      var that = this;
      var router_back = {path: '/admin/list-visit_report'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      if (is_not_noti==0){
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })

      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('visit_report'),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })

      var model = data[Object.keys(data)];

      if (model.length > 0) {
        this.models = model[0];
        var dataImage =  await this.$CoreService.getFileMapFile([this.$constants.TYPE_IMAGE.IMG_REPORT], [this.models.id]);
        this.models["list_file_edit"] = dataImage.listData
        console.log('model2341234123', model)
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 24px !important;
    padding-left: 20px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.p-toolbar-group-right {
  flex-basis: 30%;
}
.p-button-warning.p-button {
  margin: 0 10px;
}
</style>