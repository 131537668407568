<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="col-6">
        <!-- Trường Status -->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Status</label>
          <div class="p-col">
            <v-select
                :clearable="false"
                title="Status"
                class=""

                v-model="listOptionSelected"
                :options="listOption"
                :reduce="property_item => property_item"
                label="name" >
                <span slot="no-options" @click="$refs.select.open = true">
                  Empty data
                </span>
            </v-select>
            <input class="input_tmp_validator" id="status">
          </div>
        </div>

        <!-- Trường Category -->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Category</label>
          <div class="p-col">
            <v-select
                :disabled="mode=='edit'"
                :clearable="false"
                title="Category"
                class=""
                @search="SearchDocsCate"
                placeholder="Choose"
                v-model="category_id"
                :options="listDocsCate"
                :reduce="doc_cate_item => doc_cate_item.id"
                label="name"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="category">
          </div>
        </div>

        <!-- Trường Case ID (chỉ hiển thị khi mode là 'edit') -->
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Case ID</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="case_id" :disabled="true" placeholder="Input"/>
          </div>
        </div>

        <!-- Trường Property ID (chỉ hiển thị khi mode là 'edit') -->
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Property ID</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="property_id" :disabled="true" placeholder="Input"/>
          </div>
        </div>

        <!-- Trường Property -->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Property</label>
          <div class="p-col">
            <v-select
                :disabled="mode=='edit'"
                :clearable="false"
                title="Property"
                class=""

                placeholder="Choose"
                v-model="selProperty"
                :options="listProperty"
                label="name"
            >
              <span slot="no-options" @click="$refs.select.open = false">Empty data</span>
              <template slot="option" slot-scope="property_item">
                {{ `${property_item.code} - ${property_item.name}` }}
              </template>
              <template slot="selected-option" slot-scope="property_item">
                {{ `${property_item.code} - ${property_item.name}` }}
              </template>
            </v-select>
            <input class="input_tmp_validator" id="property_selected">
          </div>
        </div>

        <!-- Trường Created by (chỉ hiển thị khi mode là 'edit') -->
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Created by</label>
          <div class="p-col">
            <InputText type="text" class="" v-model="created_by" :disabled="true" placeholder="Input"/>
          </div>
        </div>

        <!-- Trường Created date (chỉ hiển thị khi mode là 'edit') -->
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right">Created date</label>
          <div class="p-col">
            <InputText type="text" :value="getFilterDate1(created_date)" :disabled="true" placeholder="Input" />
          </div>
        </div>

        <!-- Trường Week -->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">Week</label>
          <div class="p-col">
            <v-select
                :disabled="mode=='edit'"
                :clearable="false"
                title="Week"
                class=""

                placeholder="Choose"
                v-model="selVisit"
                :options="listVisit"
            >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
              <template slot="option" slot-scope="option">
                {{ `${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)}` }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ `${getFilterDate(option.visit_start)} - ${getFilterDate(option.visit_end)}` }}
              </template>
            </v-select>
            <input class="input_tmp_validator" id="visit_selected">
            <span v-if="mode === 'edit'">
              <router-link :to="`/admin/list-visit/edit/${visit_id}`" class="view-this-visit">View this visit >></router-link>
            </span>
          </div>
        </div>
      </div>
      <!-- Trường Description -->
      <div class="col-12">
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Description</label>
          <div class="p-col">
            <input class="input_tmp_validator" id="validator_content_description">
            <CkditorCore
                v-if="descriptionLoaded"
                :contentSet="description"
                @newdata="handleDataCkditor($event, 0, 'description')"
            ></CkditorCore>
          </div>
        </div>
      </div>
      <div class="col-6">
        <!-- Trường Images -->
        <div class="p-card-field">
          <!--           :class="`${mode=='add' ? 'txt-required' : ''}`"-->
          <label class="p-col-fixed txt-right">Images ({{ list_file.length }})</label>
          <div class="p-col">
            <div class="cus-text opacity-text-file">Recommend 1.000x1.000px - Maximum: 10 MB</div>
            <div class="cus-text opacity-text-file">Accepted file: JPG, JPEG, PNG, GIF.</div>
            <input

                class="p-inputtext p-component"
                accept="image/*"
                name="images[]"
                id="images_service"
                type="file"
                @change="onFileSelectFile"
                multiple
            />
            <input class="input_tmp_validator" id="list_file_images">
            <div class="cus-text style_list_image">
              <div class="box-image" v-for="(item, index) in list_file" :key="index">
                <img class="style_img" :src="item.objectURL" />
                <div  class="button-clear" @click="onFileClear(item, index)">
                  <img src="/assets/images/icon/cancel_black_24dp.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Trường Attached video -->
        <div class="p-card-field">
          <label class="p-col-fixed txt-right"  :class="`${mode=='add' ? 'txt-required' : ''}`">Attached video ({{ list_video.length }})</label>
          <div class="p-col">
            <div class="cus-text opacity-text-file">Maximum: 500 MB</div>
            <div class="cus-text opacity-text-file">Accepted file: mov, mp4</div>
            <input

                class="p-inputtext p-component"
                accept="video/mp4, video/quicktime"
                name="videos[]"
                id="videos_service"
                type="file"
                @change="onFileSelectVideo($event,$constants.TYPE_IMAGE.VIDEO_REPORT)"
                multiple
            />
            <input class="input_tmp_validator" id="list_file_videos">
            <LoadVideo
                :process_upload_videos="list_video"
                @delete-video="onVideoClearVideo"

            ></LoadVideo>

<!--            <div class="cus-text style_list_video">-->
<!--              <ul>-->
<!--                <li v-for="(item, index) in list_video" :key="index" class="video-list-item">-->
<!--                  <span class="name-file-video">{{ item.file.name }}</span>-->
<!--                  <span class="icon-view" @click="onVideoClearVideo(item)">-->
<!--                    <i class="fas fa-eye"></i>-->
<!--                  </span>-->
<!--                  <span class="icon-delete" @click="onVideoClear(item, index)">-->
<!--                    <i class="far fa-trash-alt"></i>-->
<!--                  </span>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </div>
      </div>

      <!-- Nút điều khiển (Edit, Add, Back) -->
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back_button"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
//import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
import DataServices from "@/core/DataServices";
import CkditorCore from '../CkditorCore';
import LoadVideo from './load_video.vue';

export default {
  components: {
    CkditorCore,
    LoadVideo

  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      obj_data:{
        filename:"",

      },

      comFilename:1,
      dataBookingRound: [],
      selProperty: null,
      listProperty: [],
      week : [],
      listOption: [
        { id: 'ST001', name: 'Open' },
        { id: 'ST002', name: 'Closed' },
        { id: 'ST003', name: 'Cancelled' }
      ],
      // Thiết lập mặc định là "Open"
      listOptionSelected: { id: 'ST001', name: 'Open' },
      listPropertyAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local : moment.tz.guess(true),
      componentKey: 0,
      file_pdf :[],
      maxFileSize: 2097152*50 ,
      list_file: [],
      list_video: [],
      selVisit: null,
      listAssign: [],
      listVisit: [],
      listVisitAll: [],
      category_id : '',
      description: '',
      descriptionLoaded: false,
      list_id_file_delete: [],
      list_id_file_video_delete : [],
      created_date : '',
      created_by : '',
      property_id : '',
      case_id : '',
      visit_id : '',
      user_id : '',
      content : '',
      code : '',
      name: '',
      checkedProminent : false,
      checkedPromotion : false,
      checkedMember : false,
      ordinal : 1,
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFiles: 10,
      categories: [],
      selcategory: null,
      saving:false,
      code_exist : false,
      descriptionEN : '',
      price_promotion_invalid:false,
      imgDelete : [],
      Type:[{value : 1 , name : 'Thành viên'},{value : 2 , name : 'Cửa hàng'}],
      selType : {value : 2 , name : 'Cửa hàng'},
      ListUsers : [],
      selUser : null,
      ListStore : [],
      store_id : null,
      videos : [],
      process_upload_video:{
        status:1,//1.chưa upload,2.đang upload,3.upload thành công
        progressBar:1,//default
        data:null,
      },
      process_upload_trailer:{
        status:1,//1.chưa upload,2.đang upload,3.upload thành công
        progressBar:1,//default
        data:null,
      },
      process_upload_video_en:{
        status:1,//1.chưa upload,2.đang upload,3.upload thành công
        progressBar:1,//default
        data:null,
      },
      name_store : '',
      stores : [],
      selcategoryDel: [],
      selcategorynew: [],
      name_en : '',
      content_en : '',
      checkedUseVideo : true,
    }
  },
  watch: {
    async "selVisit"() {
      this.user_fullname = this.selVisit?.fullname || '';
    },
    async "selProperty"() {
      if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
        await this.getListVisit();
        let type_arr = this.selService?.type_of_property_arr || [];
        this.price = type_arr.filter(f => f.type_id == this.selProperty?.type_id)[0]?.price || 0;
      }
    },
    /* async "selProperty"() {
       if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
         this.address = this.selProperty?.address;
         this.partArr = this.selProperty?.part;
         if (this.mode == 'add') {
           var arr = [];
           this.partArr.map(m => {
             arr.push({ord: m.order_booking, id: m.id});
           })
           arr.map(m=> {
             var tmp = [];
             for (let i =0; i < arr.length; i++) {
               let a = m.ord - i + arr.length;
               if (a > arr.length) {
                 a = a - arr.length;
               }
               tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
             }
             this.dataBookingRound.push(tmp);
           })
         }
         this.componentKey++;
       }
     },
     "start_booking_date"() {
       if (this.partArr.length) {
         let days = 14 * this.partArr.length;
         this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
       }
     }*/
  },
  async mounted() {
    await this.getListProperty();
    await this.getListDocsCate();
    await this.getListWeekVisit();
    var that =this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      if (this.model?.status === 'ST001') {
        console.log('dung');
        this.listOptionSelected = this.listOption.find(option => option.id === 'ST001');
      } else if (this.model?.status === 'ST002') {
        console.log('dung');
        this.listOptionSelected = this.listOption.find(option => option.id === 'ST002');
      } else if (this.model?.status === 'ST003') {
        console.log('dung');
        this.listOptionSelected = this.listOption.find(option => option.id === 'ST003');
      } else {
        console.log('sai');
      }

      this.category_id = this.model?.report_category?.id;
      this.case_id = this.model?.code;
      this.selProperty =  this.model?.property?.name;
      console.log('this.model?.status',this.model?.status)
      if (this.model?.status === 'ST001'){
        console.log('dung')
        this.listOptionSelected.name = 'Open';
      }else if(this.model?.status === 'ST002'){
        console.log('dung')
        this.listOptionSelected.name = 'Closed';
      } else if (this.model?.status ==='ST003'){
        console.log('dung')
        this.listOptionSelected.name = 'Cancelled';
      } else {
        console.log('sai')
      }
      // this.listOptionSelected.name = this.model?.status;
      this.selProperty = {id : this.model.property.id, name : this.model.property.name, code: this.model.property.code};
      this.selVisit = {id : this.model.visit.id, uid: this.model.visit.uid,  week_number: this.model.visit.week_number, visit_start: this.model.visit.visit_start, visit_end: this.model.visit.visit_end};
      this.description = this.model?.content;
      this.descriptionLoaded = true;  // Đánh dấu đã tải để CKEditor được render
      this.created_by =  this.model?.user?.fullname;
      this.created_date = this.model?.created_at;
      this.property_id = this.model?.property?.code;
      this.visit_id = this.selVisit?.id;
      if (!this.$commonFuction.isEmpty(this.model?.list_file_edit)) {
        var list_file_edit = this.model?.list_file_edit
        for (let i = 0; i < list_file_edit.length; i++) {
          var item_file = list_file_edit[i]
          var obj = { id: item_file.id, 'name': '', 'file': '', 'objectURL': item_file.url, type: 'old_edit' }
          this.list_file.push(obj)
        }
      }
      //lấy data video
      this.$CoreService.getFile(this.$constants.TYPE_IMAGE.VIDEO_REPORT, this.model.id, 0).then(function (response) {
        var listData = response.listData;

        if (listData.length > 0) {
          for (let i = 0; i < listData.length; i++) {
            var dataVideo = listData[i];

            // Kiểm tra xem file có phải là video không trước khi thêm vào list_video
            if (dataVideo.url.includes('.mp4') || dataVideo.url.includes('.mov')) {
              dataVideo.old = 1;  // Đánh dấu đây là video cũ (đã tồn tại)
              console.log('dataVideodataVideo', dataVideo);
              that.list_video.push({
                file: null,  // Video cũ nên không có file
                process_key: `process_upload_video_${i}`,  // Tạo process key cho video
                status: 3,  // Đặt trạng thái thành công vì đây là video đã tồn tại
                progressBar: 100,  // Đặt thanh tiến trình hoàn thành
                data: dataVideo , // Thêm dữ liệu video
                type: 'old_edit'
              });
            }
          }
        }
      });


      console.log("thismodelthismodelthismodelthismodel",this.model)
      this.componentKey++;
    }else {
      this.descriptionLoaded = true;
      console.log('property1aaaa', this.model)
      //thêm mới
      this.componentKey++;
    }
  },
  methods: {
    async onFileSelectPdf(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:",files)
      console.log("files.length:",files.length)
      if(files.length > 0) {
        let acceptTags = ['pdf']
        console.log("files.length1111111:",files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:",files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: pdf");
            return;
          }
          //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:",files[i].name)
          that.obj_data.filename =files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:',file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:",file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:", arrTemp)
          this.file_pdf = [];
          this.file_pdf = this.file_pdf.concat.apply(this.file_pdf, arrTemp);
        }
      }
    },
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'description') {
        this.description = dataContent;
      }
    },
    // Xử lý khi xóa video
    async onFileSelectVideo(event, key_upload) {
      var files = event.target.files; // Lấy tất cả các file được chọn
      let acceptTags = ['mov', 'mp4'];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        // Kiểm tra định dạng file
        if (!acceptTags.includes((file.name.split('.').pop()).toLowerCase())) {
          alert("Vui lòng upload file có định dạng sau: mp4, mov");
          continue; // Bỏ qua file nếu không đúng định dạng
        }

        var process_key = 'process_upload_video_' + i;
        var videoData = {
          file: file,
          process_key: process_key,
          status: 1, // Trạng thái chờ upload
          progressBar: 0,
          data: null
        };
        this.list_video.unshift(videoData); // Thêm video vào đầu danh sách
        await this.processUploadVideo(file, key_upload, process_key);
      }
    }
,
    async processUploadVideo(file, key_upload, process_key) {
      var that = this;
      var video = this.list_video.find(v => v.process_key === process_key);

      if (file) {
        video.status = 2; // Đang upload
        video.progressBar = 0; // Khởi tạo thanh tiến trình

        var key_status = 'state_' + (new Date().getTime()).toString();
        var key_status_progress_bar = 'progress_' + key_status;

        var dataState = {
          status: 1, // 1: đang upload, 2: lỗi, 3: thành công
        };
        localStorage.setItem(key_status, JSON.stringify(dataState));
        localStorage.setItem(key_status_progress_bar, '0');

        // Gọi dịch vụ upload file lên AWS S3
        this.$CoreService.uploadMultilpart(file, key_status, key_status_progress_bar).then(() => {
          // Kiểm tra tiến trình upload
          var intervalUploadMultilpart = setInterval(function () {
            var progressBarUpload = localStorage.getItem(key_status_progress_bar)
                ? Math.round(parseFloat(localStorage.getItem(key_status_progress_bar)))
                : 0;
            video.progressBar = (progressBarUpload < 1) ? 1 : progressBarUpload;

            var resDataState = localStorage.getItem(key_status)
                ? JSON.parse(localStorage.getItem(key_status))
                : null;

            if (resDataState) {
              var status = resDataState.status;
              if (status == 2) {
                // Upload thất bại
                video.status = 1; // Reset trạng thái
                alert("Upload video thất bại! Vui lòng thử lại.");
                clearInterval(intervalUploadMultilpart);
              } else if (status == 3) {
                // Upload thành công
                video.status = 3;
                video.progressBar = 100;
                video.data = that.$commonFuction.convertJsonObject(resDataState.data); // Lưu dữ liệu video
                clearInterval(intervalUploadMultilpart);
              }
            }
          }, 1000); // Kiểm tra tiến trình mỗi giây
        });
      }
    },
    getFilterDate(date) {
      return moment.tz(date, this.time_zone_local).format("LL");
    },
    getFilterDate1(date) {
      const validDate = moment.tz(date, this.time_zone_local);
      if (!validDate.isValid()) {
        return 'Invalid date';
      }
      return validDate.format("LLL");
    },
    async getListWeekVisit(search, loading) {
      console.log('loading', loading)
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.week_number = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListWeekVisitCheckinCheckOut(where_key);
        this.week = list.list;
        console.log('this.weekthis.week', this.week)
      } else {
        // cosnole.log("dasdasd")
        this.week = this.listPropertyAll
      }
    },
    async SearchVisit(search, loading) {
      console.log(loading);
      if (!this.selProperty?.id) {
        this.listVisit = [];
        this.listVisitAll = [];
        return;
      }
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
          property_id: {_eq: this.selProperty?.id},
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('visit', {"fields": `id week_number visit_start visit_end user {id fullname}`}),
          variables: {
            where_key: where_key,
            orderBy: [{created_at: 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_visit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_visit.push({
            id: e.id,
            uid: e.user.id,
            fullname: e.user.fullname,
            week_number: e.week_number,
            visit_start: e.visit_start,
            visit_end: e.visit_end
          });
          //}
        }
        this.listVisit = list_visit;
      } else {
        // cosnole.log("dasdasd")
        this.listVisit = this.listVisitAll;
      }
    },
    async SearchProperty(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
          // booking: {id: {_is_null: true} },
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('property', {"fields": `id code name type_id`}),
          variables: {
            where_key: where_key,
            orderBy: [{created_at: 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_property.push({id: e.id, name: e.name, code: e.code, type_id: e.type_id});
          //}
        }
        this.listProperty = list_property;
      } else {
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
    async SearchPropertyWeek(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }

        var list = await this.$CoreService.getListWeekVisitCheckinCheckOut(where_key);

        // Kiểm tra dữ liệu trả về
        console.log('Dữ liệu tuần:', list.list);

        this.week = list.list.map(item => ({
          week_number: item.week_number,
          label: `Week ${item.week_number} (${this.formatDate(item.visit_start)} - ${this.formatDate(item.visit_end)})`,
        }));
      } else {
        this.week = this.listPropertyAll;
      }
    },
    formatDate(dateString) {
      const options = {year: 'numeric', month: 'long', day: 'numeric'};
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    onVideoSelectFile(event) {
      const files = event.target.files;
      const maxSize = 500 * 1024 * 1024;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          alert('File size exceeds 500 MB');
          continue;
        }

        const validFormats = ['video/mp4', 'video/quicktime'];
        if (!validFormats.includes(file.type)) {
          alert('Invalid file format. Accepted formats: mp4, mov');
          continue;
        }

        const objectURL = URL.createObjectURL(file);

        this.list_video.push({
          file: file,
          objectURL: objectURL
        });
      }
    },

    viewVideo(item) {
      window.open(item.objectURL); // Mở video trong cửa sổ mới
    },
    beforeDestroy() {
      this.list_video.forEach(item => {
        URL.revokeObjectURL(item.objectURL);
      });
    },
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;

      // Kiểm tra số lượng ảnh đã chọn trước khi thêm
      if (this.list_file.length + files.length > 10) {
        alert("You can only upload a maximum of 10 images.");
        event.target.value = null; // Reset giá trị của input file
        return;
      }

      if (files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG'];
        const maxFileSize = 10 * 1024 * 1024; // 2MB in bytes

        for (let i = 0; i < files.length; i++) {
          const fileExtension = files[i].name.split('.').pop();
          const fileSize = files[i].size;

          // Kiểm tra định dạng file
          if (!acceptTags.includes(fileExtension)) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            event.target.value = null;
            return;
          }

          // Kiểm tra kích thước file
          if (fileSize > maxFileSize) {
            alert("Each image must be smaller than 2MB.");
            event.target.value = null;
            return;
          }

          // Nếu file hợp lệ, thêm vào list
          that.obj_data.filename = files[i].name;
          this.comFilename++;
        }

        // Lưu trữ các tệp hợp lệ
        var arrTemp = [];
        for (let file of files) {
          file.source = file;
          file.objectURL = window.URL.createObjectURL(file);
          arrTemp.push(file);
        }

        // Cập nhật danh sách file
        this.list_file = this.list_file.concat(arrTemp);
      }

      // Reset giá trị của input file để nó sẵn sàng cho lần chọn tệp tiếp theo
      event.target.value = null;
    },
    onFileClear(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete.push(item);
      }
      this.list_file.splice(index, 1);
    },
    onVideoClearVideo(video, index) {
      // Xóa video khỏi danh sách hiển thị
      this.list_video.splice(index, 1);

      // Kiểm tra nếu video là file cũ đã được upload trước đó (có thuộc tính 'old')
      if (video.data?.old) {
        this.list_id_file_video_delete.push({
          id: video.data.id,
          name: "",
          file: "",
          objectURL: video.data.url,
          type: "old_edit"
        });
      }

      // Nếu video vừa mới được chọn (file mới), không cho phép lưu nó
      if (!video.data?.old) {
        // Xóa video mới khỏi danh sách lưu
        const newVideoIndex = this.list_video.findIndex(v => v.file === video.file);
        if (newVideoIndex > -1) {
          this.list_video.splice(newVideoIndex, 1);
        }
      }
    }




    ,
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListProperty() {
      var where_key = {
        deleted: {_eq: false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListProperty1(where_key);
      this.listProperty = list.list;
      this.listPropertyAll = list.list;
      console.log('this.listPropertyAll', this.listPropertyAll)
    },


    async getListDocsCate() {
      var where_key = {
        deleted: {_eq: false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListCateReport(where_key);
      this.listDocsCate = list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListCateReport(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      console.log('Bắt đầu kiểm tra dữ liệu...');

      // Xóa các lỗi xác thực trước đó
      this.validationErrors = {};

      let isFormInvalid = false;

      // Kiểm tra trường Status (listOptionSelected)
      let statusElement = document.getElementById("status");
      if (!this.listOptionSelected || this.$commonFuction.isEmptyObject(this.listOptionSelected)) {
        statusElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        statusElement.reportValidity();
        isFormInvalid = true;
      } else {
        statusElement.setCustomValidity("");
      }

      // Kiểm tra trường Category (category_id)
      let categoryElement = document.getElementById("category");
      if (!this.category_id || this.$commonFuction.isEmptyObject(this.category_id)) {
        categoryElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        categoryElement.reportValidity();
        isFormInvalid = true;
      } else {
        categoryElement.setCustomValidity("");
      }

      // Kiểm tra trường Property (selProperty)
      let propertyElement = document.getElementById("property_selected");
      if (!this.selProperty || this.$commonFuction.isEmptyObject(this.selProperty)) {
        propertyElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        propertyElement.reportValidity();
        isFormInvalid = true;
      } else {
        propertyElement.setCustomValidity("");
      }

      // Kiểm tra trường Week (selVisit)
      let visitElement = document.getElementById("visit_selected");
      if (!this.selVisit || this.$commonFuction.isEmptyObject(this.selVisit)) {
        visitElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        visitElement.reportValidity();
        isFormInvalid = true;
      } else {
        visitElement.setCustomValidity("");
      }

      // Kiểm tra trường Description (CKEditor)
      let descriptionElement = document.getElementById("validator_content_description");
      if (!this.description || this.$commonFuction.isEmptyObject(this.description)) {
        descriptionElement.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        descriptionElement.reportValidity();
        isFormInvalid = true;
      } else {
        descriptionElement.setCustomValidity("");
      }

      // Kiểm tra trường Images (list_file)
      // let list_file = document.getElementById("list_file_images");
      // if (this.list_file.length === 0) {
      //   list_file.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      //   list_file.reportValidity();
      //   isFormInvalid = true;
      // } else {
      //   list_file.setCustomValidity("");
      // }
      //
      // // Kiểm tra trường Video (list_video)
      // let list_video = document.getElementById("list_file_videos");
      // if (this.list_video.length === 0) {
      //   list_video.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      //   list_video.reportValidity();
      //   isFormInvalid = true;
      // } else {
      //   list_video.setCustomValidity("");
      // }

      // Kiểm tra tính hợp lệ của toàn bộ form
      let inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        inpObj.reportValidity();
        isFormInvalid = true;
      }

      return isFormInvalid; // Trả về true nếu form không hợp lệ
    },


    removeProduct(index) {
      this.products.splice(index, 1);
    },
    addProduct() {
      this.products.push({
        product: {price: 0},
        price: 0,
        qty: 0
      })
    },
    async getListService() {

      var where_key = {
        deleted: {_eq: false},
        type_of_price: {_neq: '3'},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('service', {"fields": `id name type_of_price type_of_property_arr{price type_id}`}),
        variables: {
          where_key: where_key,
          orderBy: [{created_at: 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_service = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
        list_service.push({
          id: e.id,
          name: e.name,
          type_of_price: e.type_of_price,
          type_of_property_arr: e.type_of_property_arr
        });
        //}
      }
      this.listService = list_service;
      this.listServiceAll = list_service;
    },

    async SearchService(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
          type_of_price: {_neq: '3'},
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('service', {"fields": `id name type_of_price type_of_property_arr{price type_id}`}),
          variables: {
            where_key: where_key,
            orderBy: [{created_at: 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_service = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_service.push({
            id: e.id,
            name: e.name,
            type_of_price: e.type_of_price,
            type_of_property_arr: e.type_of_property_arr
          });
          //}
        }
        this.listService = list_service;
      } else {
        // cosnole.log("dasdasd")
        this.listService = this.listServiceAll
      }
    },
    async getListProduct() {
      if (!this.selService?.id) {
        this.listProduct = [];
        this.listProductAll = [];
        return;
      }
      var where_key = {
        deleted: {_eq: false},
        active: {_eq: true},
        service_id: {_eq: this.selService?.id},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('product', {"fields": `id name price`}),
        variables: {
          where_key: where_key,
          orderBy: [{created_at: 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_produce = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
        list_produce.push({id: e.id, name: e.name, price: e.price});
        //}
      }
      this.listProduct = list_produce;
      this.listProductAll = list_produce;
    },

    async SearchProduct(search, loading) {
      console.log(loading);
      if (!this.selService?.id) {
        this.listProduct = [];
        this.listProductAll = [];
        return;
      }
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
          active: {_eq: true},
          service_id: {_eq: this.selService?.id},
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('product', {"fields": `id name price`}),
          variables: {
            where_key: where_key,
            orderBy: [{created_at: 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_produce = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_produce.push({id: e.id, name: e.name, price: e.price});
          //}
        }
        this.listProduct = list_produce;
      } else {
        // cosnole.log("dasdasd")
        this.listProduct = this.listProductAll
      }
    },
    async getListVisit() {
      if (!this.selProperty?.id) {
        this.listVisit = [];
        this.listVisitAll = [];
        return;
      }

      // Add filtering by status ST006 and ST007
      var where_key = {
        deleted: {_eq: false},
        property_id: {_eq: this.selProperty?.id},
        status: {_in: ['ST006', 'ST007']} // Filter for status ST006 and ST007
      }

      // Querying the 'visit' table with the updated 'where_key'
      let {data} = await this.$apollo.query({
        query: DataServices.getList('visit', {
          "fields": `id week_number visit_start visit_end status user {id fullname}` // Added status in fields
        }),
        variables: {
          where_key: where_key,
          orderBy: [{created_at: 'desc'}]
        },
        fetchPolicy: "network-only"
      });

      var list = data[Object.keys(data)];
      var list_visit = [];

      // Loop through and construct the list of filtered visits
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_visit.push({
          id: e.id,
          uid: e.user.id,
          fullname: e.user.fullname,
          week_number: e.week_number,
          visit_start: e.visit_start,
          visit_end: e.visit_end,
          status: e.status // Including the status for clarity
        });
      }

      // Update the listVisit with the filtered results
      this.listVisit = list_visit;
      this.listVisitAll = list_visit;
    },


    async getListAssign() {

      var where_key = {
        deleted: {_eq: false},
        // booking: {id: {_is_null: true} },
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users', {"fields": `id fullname`}),
        variables: {
          where_key: where_key,
          orderBy: [{created_at: 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_assign = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
        list_assign.push({id: e.id, fullname: e.fullname});
        //}
      }
      this.listAssign = list_assign;
      this.listAssignAll = list_assign;
    },

    async SearchAssign(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: {_eq: false},
          // booking: {id: {_is_null: true} },
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users', {"fields": `id fullname`}),
          variables: {
            where_key: where_key,
            orderBy: [{created_at: 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_assign = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          //if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_assign.push({id: e.id, fullname: e.fullname});
          //}
        }
        this.listAssign = list_assign;
      } else {
        // cosnole.log("dasdasd")
        this.listAssign = this.listAssignAll;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      const validVideos = this.list_video.filter(video => video.status !== 1);

      var params = {
        list_id_file_delete: this.list_id_file_delete,
        list_id_file_video_delete : this.list_id_file_video_delete,
        property_id: this.selProperty?.id,
        visit_id: this.selVisit?.id,
        uid: this.selVisit?.uid,
        list_file: this.list_file,
        status: this.listOptionSelected.id,
        category_id: this.category_id,
        description: this.description,
        list_video: validVideos.map(video => video.data),
      }
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.view-this-visit {
  color: #1D76BC;
  font-weight: 500;
}

/* Căn chỉnh danh sách video */
.style_list_video ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.video-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

/* Định dạng tên file */
.name-file-video {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px; /* Đảm bảo tên file không tràn ra ngoài */
}

/* Icon xem video */
.icon-view {
  color: #007bff;
  margin-left: 15px;
  cursor: pointer;
}

.icon-view i {
  font-size: 18px;
}

/* Icon xóa video */
.icon-delete {
  color: #e74c3c;
  margin-left: 15px;
  cursor: pointer;
}

.icon-delete i {
  font-size: 18px;
}

/* Hover effect cho các icon */
.icon-view:hover,
.icon-delete:hover {
  opacity: 0.7;
}

/* Căn chỉnh để các icon nằm gần nhau */
.video-list-item span {
  display: inline-flex;
  align-items: center;
}


.p-formgrid {
  padding-top: 20px;
}

.main-style-button {
  background-color: #0086C4 !important;
}

.custom-select {
  width: 100%;
  padding-right: 30px;
  /* Tăng khoảng cách bên phải để không che khuất mũi tên */
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg' fill='%23222'%3E%3Cpath d='M7 10L3 6h8L7 10z'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-color: #fff;
  background-size: 12px;
  appearance: none;
  /* Có thể giữ lại hoặc loại bỏ */
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.opacity-text-file {
  opacity: 0.5;
}

.calendar-css-margin1 {
  padding-right: 0px !important;
}

.calendar-css-margin2 {
  padding-left: 0px !important;
}

.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}

.cus-text {
  margin-left: 2px;
  margin-top: 4px;
}

.style-box-phone {
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;

  .p-dropdown {
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }

  .p-focus {
    border: 0px;
    box-shadow: none;
  }

  .p-dropdown:focus {
    border: 0px;
    box-shadow: none;
  }

  input {
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }

  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}

.style_list_image {
  display: flex;
  flex-wrap: wrap;
  /* Cho phép các phần tử xuống dòng */
  gap: 10px;

  /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }

    .button-clear {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }
  }
}

.style_box_room {
  display: flex;
}

.style_box_lat_log {
  display: flex;

  .box_lat_log {
    width: 50%;
  }
}

.style_title_group {
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}

.style_box_price {
  padding-left: 35px !important;
}

.style_rad_button {
  label {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.style_btn_add_type {
  width: 60px;
}
</style>
