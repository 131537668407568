<template>
  <div class="progress-wrapper">
    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.progress-wrapper {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  height: 8px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.2s ease;
}
</style>
