<template>
  <div>
    <!-- Hiển thị danh sách các video -->
    <div v-for="(video, index) in process_upload_videos" :key="index" class="video-list-item">

      <!-- Hiển thị tên file từ URL hoặc file.name đã được rút gọn -->
      <div class="file-name">
        <!-- Sử dụng video.file.name hoặc video.data.url nếu có -->
        <span>{{ getFileName(video) }}</span>
        <!-- Icon xem video (chỉ hiển thị khi upload thành công) -->
        <span class="icon-view" @click="viewFullScreen(video)" v-if="video.status === 3">
          <i class="fas fa-eye"></i>
        </span>
        <!-- Icon xóa video -->
        <span class="icon-delete" @click="onVideoClearVideo(video, index)">
          <i class="far fa-trash-alt"></i>
        </span>
      </div>

      <!-- Hiển thị thanh tiến trình khi đang upload -->
      <progress-bar v-if="video.status === 2" :progress="video.progressBar" />
    </div>
  </div>
</template>

<script>
import ProgressBar from './progress-bar.vue'

export default {
  components: {
    ProgressBar,
  },
  props: {
    process_upload_videos: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    console.log('process_upload_videosprocess_upload_videos',this.process_upload_videos)
  },
  methods: {
    // Lấy tên file từ URL hoặc từ đối tượng file
    getFileName(video) {
      if (video.file && video.file.name) {
        return this.getFileNameFromUrl(video.file.name);
      } else if (video.data && video.data.url) {
        return this.getFileNameFromUrl(video.data.url);
      }
      return '[Unknown File]'; // Trường hợp không có tên file hoặc URL
    },

    // Lấy tên file từ URL và rút gọn nếu quá dài
    getFileNameFromUrl(url) {
      console.log('urlurlurlurlurl', url);
      if (url) {
        // Giải mã URL để loại bỏ các ký tự mã hóa
        const decodedUrl = decodeURIComponent(url);

        // Lấy phần sau dấu '/' cuối cùng để lấy tên file
        const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);

        // Xử lý tên file dài
        if (fileName.length > 30) {
          const firstPart = fileName.substring(0, 15);  // Lấy 15 ký tự đầu
          const lastPart = fileName.substring(fileName.length - 10);  // Lấy 10 ký tự cuối
          return `${firstPart}...${lastPart}`;  // Trả về tên file rút gọn
        }
        console.log('fileNamefileNamefileNamefileName',fileName)
        return fileName;  // Trả về tên file đầy đủ nếu không quá dài
      }
      return '';
    }
,

    // Hàm để mở video ở chế độ toàn màn hình
    viewFullScreen(video) {
      if (video.data && video.data.url) {
        const videoUrl = video.data.url;
        const videoElement = document.createElement('video');
        videoElement.src = videoUrl;
        videoElement.controls = true;
        videoElement.style.position = 'fixed';
        videoElement.style.top = '0';
        videoElement.style.left = '0';
        videoElement.style.width = '100%';
        videoElement.style.height = '100%';
        videoElement.style.zIndex = '9999';
        videoElement.style.backgroundColor = 'black';
        document.body.appendChild(videoElement);

        videoElement.requestFullscreen();
        videoElement.addEventListener('fullscreenchange', () => {
          if (!document.fullscreenElement) {
            document.body.removeChild(videoElement);
          }
        });

        videoElement.addEventListener('ended', () => {
          document.body.removeChild(videoElement);
        });
      } else {
        alert('Video chưa sẵn sàng hoặc chưa có URL!');
      }
    },

    // Hàm để xóa video
    onVideoClearVideo(video, index) {
      this.$emit('delete-video', video, index); // Gửi sự kiện lên component cha
    }
  }
}
</script>
